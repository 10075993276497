import {
  Switch,
  useTheme,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../theme";

const SwitchComponent = ({
  label = "", // Label before switch
  checked = false,
  onChange,
  disabled = false,
  type = "default", // 'default' | 'warning' | 'action'
  fontSize = "13px",
  customStyles = {},
  checkedIndicator = "",
  uncheckedIndicator = "",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const getSwitchColor = () => {
    if (type === "warning") return colors.redAccent[500];
    if (type === "action") return colors.greenAccent[600];
    return colors.blueAccent[700];
  };

  return (
    <Box
      display={isMobileOrTablet ? "grid" : "flex"}
      alignItems="center"
      gap={1}
      sx={customStyles}
    >
      {/* Main label */}
      <Typography
        variant="body1"
        sx={{ fontSize, minWidth: "200px" }} // adjust width if needed
      >
        {label}
      </Typography>

      <Box display="flex" alignItems="center">
        {/* Left label */}
        <Typography
          variant="body2"
          sx={{
            fontSize,
            color: checked ? colors.grey[400] : getSwitchColor(),
            fontWeight: checked ? "normal" : "bold",
          }}
        >
          {uncheckedIndicator}
        </Typography>

        {/* Switch */}
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: getSwitchColor(),
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: getSwitchColor(),
            },
          }}
        />

        {/* Right label */}
        <Typography
          variant="body2"
          sx={{
            fontSize,
            color: checked ? getSwitchColor() : colors.grey[400],
            fontWeight: checked ? "bold" : "normal",
          }}
        >
          {checkedIndicator}
        </Typography>
      </Box>
    </Box>
  );
};

export default SwitchComponent;
