import ApiService from "./ApiService";
import { UserGroupHierarchy } from "../config/accessibilities";

class AuthService {
  constructor() {
    this.apiService = new ApiService(
      process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
    );
    this.tokenKey = "authToken";
    this.user = null;
    this.permissions = [];
    this.initAutoLogoutCheck();
  }

  async login(username, password) {
    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const response = await this.apiService.post(
        `${apiVersion}\\users\\login`,
        {
          username,
          password,
        }
      );
      const { token, data } = response;

      // Store token and user data
      this.setToken(token);
      this.setUser(data.user);
      this.setPermissions(
        data.user.group_id.accessibility[0]?.permission || []
      );
      return data.user; // Return user data for immediate use
    } catch (error) {
      console.error("Login failed:", error.message);
      throw new Error(error.response?.data?.message || "Login failed");
    }
  }

  // Logout and clear stored data
  async logout(apiCall = true) {
    try {
      if (apiCall) {
        const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
        try {
          await this.apiService.post(`${apiVersion}\\users\\logout`);
        } catch (error) {
          if (error.response?.status === 401) {
            console.warn("Session already expired, proceeding with logout...");
          } else {
            throw error; // Rethrow if it's a different error
          }
        }
      }

      this.clearToken();
      this.clearUser();
      this.clearPermissions();
    } catch (error) {
      console.error("Logout failed:", error.message);
      throw new Error(error.response?.data?.message || "Logout failed");
    }
  }

  //Auto logout when token expired
  initAutoLogoutCheck() {
    // if(this.initAutoLogoutCheck)
  }

  // Get the current user
  getCurrentUser() {
    const user = localStorage.getItem(this.user);
    return user ? JSON.parse(user) : null;
  }

  // Get permissions
  getPermissions() {
    const permissions = localStorage.getItem("permissions");
    return permissions ? JSON.parse(permissions) : [];
  }

  // Check if a specific permission exists
  hasPermission(permission) {
    const user = this.getCurrentUser(); // Get the currently logged-in user
    if (!user) return false; // If no user is logged in, return false

    const userHierarchyLevel = user.group_id?.hierarchyLevel ?? 0; // Get user's hierarchy level
    if (userHierarchyLevel === UserGroupHierarchy.SUPER_ADMIN) {
      return true;
    }

    return this.getPermissions().includes(permission);
  }

  // Check if the user is authenticated
  isAuthenticated() {
    return !!localStorage.getItem(this.tokenKey);
  }

  // Utility methods for handling storage
  setToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  clearToken() {
    localStorage.removeItem(this.tokenKey);
  }

  setUser(user) {
    localStorage.setItem(this.user, JSON.stringify(user));
  }

  clearUser() {
    localStorage.removeItem(this.user);
  }

  setPermissions(permissions) {
    localStorage.setItem("permissions", JSON.stringify(permissions));
  }

  clearPermissions() {
    localStorage.removeItem("permissions");
  }
}

export default new AuthService();
