import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  useTheme,
} from "@mui/material";
import ButtonComponent from "../../components/ButtonComponent";
import { tokens } from "../../theme";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ApiService from "../../services/ApiService";
import { useParams, useNavigate } from "react-router-dom";

const NewPasswordSetup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  const handleSubmit = async () => {
    setLoading(true);
    setMessage("");

    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const response = await apiService.post(
        `${apiVersion}/users/resetPassword/${token}`,
        {
          password,
          passwordConfirm,
        }
      );
      setMessageType("success");
      setMessage(response.message);
      setTimeout(() => navigate("/login"), 5000);
    } catch (error) {
      setMessageType("error");
      setMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="90%"
      bgcolor="colors.primary[400]"
    >
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          textAlign: "center",
          width: "400px",
          borderRadius: "10px",
          background: colors.blueAccent[800],
        }}
      >
        <Typography variant="h4" mb={2} color={colors.greenAccent[400]}>
          Reset Password
        </Typography>

        {message && <Typography color={messageType}>{message}</Typography>}

        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ButtonComponent
            onClick={handleSubmit}
            disabled={loading}
            customStyles={{ mt: 3 }}
            label={loading ? <CircularProgress size={24} /> : "Update Password"}
          ></ButtonComponent>
        </form>
      </Paper>
    </Box>
  );
};

export default NewPasswordSetup;
