import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ApiService from "../../services/ApiService"; // Service to handle API calls
import { toast } from "react-toastify";
import SwitchComponent from "../../components/SwitchComponent";
import LoadingSpinnerComponent from "../../components/LoadingSpinner";
import Divider from "@mui/material/Divider";

const VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
};

export const getOrCreatePushSubscription = async () => {
  if (!("serviceWorker" in navigator)) {
    toast.error("Service workers are not supported in this browser.");
    return null;
  }

  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    toast.warn("Notifications permission denied.");
    return null;
  }

  const registration = await navigator.serviceWorker.ready;

  let subscription = await registration.pushManager.getSubscription();

  if (!subscription) {
    try {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      });
      console.log("📬 New push subscription:", subscription);
    } catch (err) {
      console.error("⚠️ Failed to subscribe:", err);
      toast.error("Failed to subscribe for notifications.");
      return null;
    }
  } else {
    console.log("📬 Existing push subscription found:", subscription);
  }

  return subscription;
};

const GeneralSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [alarmMachineNotification, setAlarmMachineNotification] =
    useState(false);
  const [disableAlarmMachineNotification, setDisableAlarmMachineNotification] =
    useState(false);

  const apiService = new ApiService(
    process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_PORT
  );

  // Fetch SMTP settings on component mount
  useEffect(() => {
    const fetchNotificationSubscription = async () => {
      setLoading(true);
      try {
        const subscription = await getOrCreatePushSubscription();
        if (!subscription) {
          console.log(subscription);
          setDisableAlarmMachineNotification(true);
          return;
        } else {
          setDisableAlarmMachineNotification(false);
        }

        const apiVersion =
          process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_VERSION;
        const response = await apiService.post(
          `${apiVersion}/notifications/check`,
          subscription
        );

        if (response && response.subscribed) {
          setAlarmMachineNotification(true);
        } else {
          setAlarmMachineNotification(false);
        }
      } catch (error) {
        console.error("Failed to check subscription:", error);
        toast.error("Could not verify subscription");
        setDisableAlarmMachineNotification(true);
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationSubscription();
  }, []);

  const handleToggle = async (e) => {
    const isChecked = e.target.checked;
    setAlarmMachineNotification(isChecked);

    try {
      const subscription = await getOrCreatePushSubscription();
      if (!subscription) {
        toast.error("Push subscription not found");
        setDisableAlarmMachineNotification(true);
        return;
      } else {
        setDisableAlarmMachineNotification(false);
      }

      const apiVersion =
        process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_VERSION;
      const route = isChecked ? "subscribe" : "unsubscribe";

      const response = await apiService.post(
        `${apiVersion}/notifications/${route}`,
        subscription
      );

      toast.success(response.message || "Setting updated.");
    } catch (err) {
      console.error("Failed to update notification:", err);
      toast.error("Failed to update setting.");
      setDisableAlarmMachineNotification(true);
    }
  };

  return (
    <Box sx={{ width: "90%", display: "grid", height: "100%" }}>
      {loading ? (
        <Box position="relative" height="100%">
          <LoadingSpinnerComponent color={colors.primary[100]} size={100} />
        </Box>
      ) : (
        <Box>
          <Box sx={{ mb: "10px" }}>
            <Typography variant="h5" color={colors.greenAccent[400]}>
              Notification Subscription
            </Typography>
            <Typography variant="caption" mb={1} color={colors.primary[100]}>
              This settings is a device level settings.
            </Typography>
            <Box>
              <SwitchComponent
                label="Alarm Machine Notification"
                checked={alarmMachineNotification}
                onChange={handleToggle}
                type="action"
                customStyles={{ margin: 0 }}
                labelPlacement="start"
                checkedIndicator="🟢 ON"
                uncheckedIndicator="🔴 OFF"
                disabled={disableAlarmMachineNotification}
              />
            </Box>
          </Box>

          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default GeneralSettings;
