import { tokens } from "../../theme";
import {
  Box,
  useTheme,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiService from "../../services/ApiService"; // API service for fetching groups
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Signup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
    group_id: "",
  });
  const [groups, setGroups] = useState([]); // Holds the available user groups
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  useEffect(() => {
    // Fetch user groups from backend
    const fetchGroups = async () => {
      try {
        const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
        const response = await apiService.get(`${apiVersion}/user-group`); // Adjust endpoint as needed
        setGroups(response.data); // Assume response contains { groups: [{ _id, name }] }
      } catch (err) {
        console.error("Failed to fetch user groups:", err);
        toast.error("Failed to load user groups. Please try again.");
      }
    };
    fetchGroups();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // Call the signup API

      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const response = await apiService.post(
        `${apiVersion}/users/signup`,
        formData
      );

      if (response.status === "success") {
        toast.success(
          "Account created successfully!\nPlease check your email for verification"
        );

        //navigate("/login"); // Redirect to login page after signup
      } else {
        // Handle unexpected response
        setError(response.message || "Signup failed. Please try again.");
        toast.error(response.message || "Signup failed. Please try again.");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Signup failed. Please try again.";

      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="50%"
        px={2}
      >
        <Typography variant="h4" gutterBottom color={colors.greenAccent[400]}>
          Sign Up
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Confirm Password"
              name="passwordConfirm"
              type={showPasswordConfirm ? "text" : "password"}
              value={formData.passwordConfirm}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <InputLabel id="user-group-label">User Group</InputLabel>
            <Select
              labelId="user-group-label"
              id="user-group"
              name="group_id"
              value={formData.group_id}
              onChange={handleChange}
            >
              {groups.map((group) => (
                <MenuItem key={group._id} value={group._id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              padding: "10px 20px",
              mt: "10px",
            }}
          >
            Sign Up
          </Button>
        </form>

        <Typography
          variant="body2"
          color={colors.grey[200]}
          align="center"
          sx={{ mt: 2 }}
        >
          Please check your email to verify your account. After verification, an
          admin will review and activate your account.
        </Typography>
      </Box>
    </Box>
  );
};

export default Signup;
