import React, { createContext, useState, useEffect } from "react";
import authService from "../services/AuthService";

export const AuthContext = createContext();

let logoutGlobal = null;

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(authService.isAuthenticated());
  const [user, setUser] = useState(authService.getCurrentUser());
  const [permissions, setPermissions] = useState(authService.getPermissions());

  useEffect(() => {
    // Update permissions if the user changes
    if (user) {
      setPermissions(authService.getPermissions());
    } else {
      setPermissions([]);
    }
  }, [user]);

  const login = async (username, password) => {
    await authService.login(username, password);
    setIsLoggedIn(true);
    setUser(authService.getCurrentUser());
    return authService.getCurrentUser();
  };

  const logout = (apiCall = true) => {
    authService.logout(apiCall);
    setIsLoggedIn(false);
    setUser(null);
  };

  logoutGlobal = logout;

  const hasPermission = (permission) => {
    return authService.hasPermission(permission);
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, setUser, login, logout, hasPermission }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const getLogoutGlobal = () => logoutGlobal;
