import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { tokens } from "../../theme";
import ApiService from "../../services/ApiService";

const ApproveUser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading"); // "loading" | "success" | "error"
  const [message, setMessage] = useState("Processing approval...");

  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  useEffect(() => {
    const approveUser = async () => {
      try {
        const response = await apiService.get(
          `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/users/approve/${token}`
        );
        setMessage(response.message);
        setStatus("success");

        setTimeout(() => navigate("/login"), 5000); // Redirect after 5s
      } catch (error) {
        setMessage(error.response.data.message);
        setStatus("error");
      }
    };

    approveUser();
  }, [token, navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="90%"
      bgcolor="colors.primary[400]"
    >
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          textAlign: "center",
          width: "400px",
          borderRadius: "10px",
        }}
      >
        {status === "loading" && (
          <>
            <CircularProgress sx={{ color: "#007bff", mb: 2 }} />
            <Typography variant="h6" color="textSecondary">
              {message}
            </Typography>
          </>
        )}

        {status === "success" && (
          <>
            <CheckCircleIcon sx={{ fontSize: 60, color: "green", mb: 2 }} />
            <Typography variant="h5" fontWeight="bold">
              Approval Successful!
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
              {message}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={() => navigate("/login")}
            >
              Go to Login
            </Button>
          </>
        )}

        {status === "error" && (
          <>
            <ErrorOutlineIcon sx={{ fontSize: 60, color: "red", mb: 2 }} />
            <Typography variant="h5" fontWeight="bold" color="error">
              Approval Failed
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
              {message}
            </Typography>
            <Button
              variant="contained"
              color="error"
              sx={{ mt: 3 }}
              onClick={() => navigate("/")}
            >
              Back to Home
            </Button>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ApproveUser;
