// src/pages/EmailVerify.js
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { toast } from "react-toastify";
import ApiService from "../../services/ApiService";

const EmailVerify = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );
  const [status, setStatus] = useState("verifying"); // 'verifying' | 'success' | 'error'

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
        const response = await apiService.get(
          `${apiVersion}/users/verifyEmail/${token}`
        );

        if (response.status === "success") {
          setStatus("success");
          toast.success("Email verified! Waiting for admin approval.");
        } else {
          setStatus("error");
          toast.error(response.message || "Email verification failed.");
        }
      } catch (err) {
        setStatus("error");
        toast.error(err.response?.data?.message || "Verification failed.");
      }
    };

    verifyEmail();
  }, [token]);

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      px={2}
    >
      {status === "verifying" && (
        <>
          <CircularProgress />
          <Typography variant="h6" mt={2}>
            Verifying your email...
          </Typography>
        </>
      )}

      {status === "success" && (
        <>
          <Typography variant="h4" color="green">
            🎉 Email Verified!
          </Typography>
          <Typography variant="body1" mt={2}>
            Your email has been successfully verified.
            <br />
            An admin will now review and activate your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginRedirect}
            sx={{ mt: 3 }}
          >
            Go to Login
          </Button>
        </>
      )}

      {status === "error" && (
        <>
          <Typography variant="h4" color="error">
            ❌ Verification Failed
          </Typography>
          <Typography variant="body1" mt={2}>
            The link may be invalid or expired. Please try signing up again.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginRedirect}
            sx={{ mt: 3 }}
          >
            Back to Login
          </Button>
        </>
      )}
    </Box>
  );
};

export default EmailVerify;
