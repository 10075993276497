import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ApiService from "../../services/ApiService"; // Service to handle API calls
import { toast } from "react-toastify";
import ButtonComponent from "../../components/ButtonComponent";

const SmtpSetup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [smtpSettings, setSmtpSettings] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const apiService = new ApiService(
    process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_PORT
  );

  // Fetch SMTP settings on component mount
  useEffect(() => {
    const fetchSmtpSettings = async () => {
      setLoading(true);
      try {
        const apiVersion =
          process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_VERSION;
        const response = await apiService.get(`${apiVersion}/settings`, {
          key: "smtp",
        });
        if (response.status === "success" && response.data.length != 0) {
          setSmtpSettings(response.data[0].value || {});
        }
      } catch (error) {
        console.error("Failed to fetch SMTP settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSmtpSettings();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSmtpSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const apiVersion =
        process.env.REACT_APP_IOT_DASHBOARD_BACKEND_API_VERSION;

      // Check if SMTP settings exist
      const existingSettings = await apiService.get(`${apiVersion}/settings`, {
        key: "smtp",
      });

      let response;
      if (existingSettings?.data?.length > 0 && existingSettings.data[0]._id) {
        // Use PATCH if settings already exist
        response = await apiService.patch(
          `${apiVersion}/settings/${existingSettings.data[0]._id}`,
          {
            key: "smtp",
            value: smtpSettings,
          }
        );
      } else {
        // Use POST if settings do not exist
        response = await apiService.post(`${apiVersion}/settings`, {
          key: "smtp",
          value: smtpSettings,
        });
      }

      if (response.status === "success") {
        toast.success("SMTP settings updated successfully!");
      } else {
        toast.error("Failed to update SMTP settings!");
      }
    } catch (error) {
      console.error("Failed to save SMTP settings:", error);
      toast.error("Error saving SMTP settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "90%" }}>
      <Typography variant="h5" mb={3} color={colors.greenAccent[400]}>
        SMTP Settings
      </Typography>

      <TextField
        label="SMTP Host"
        name="host"
        value={smtpSettings.host}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="SMTP Port"
        name="port"
        value={smtpSettings.port}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Username"
        name="username"
        value={smtpSettings.username}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Password"
        name="password"
        value={smtpSettings.password}
        onChange={handleInputChange}
        type="password"
        fullWidth
        sx={{ mb: 2 }}
      />

      <Box>
        <ButtonComponent
          onClick={handleSave}
          disabled={loading}
          customStyles={{ mt: 3 }}
          label={loading ? "Saving..." : "Save SMTP Settings"}
        ></ButtonComponent>
      </Box>
    </Box>
  );
};

export default SmtpSetup;
