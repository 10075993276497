import React from "react";
import { CircularProgress, Box } from "@mui/material";

const LoadingSpinner = ({
  size = 40,
  color = "primary",
  customStyles = {},
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      sx={{
        position: "absolute", // ensure it's centered even in relative containers
        top: 0,
        left: 0,
        ...customStyles,
      }}
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
};

export default LoadingSpinner;
