import { tokens } from "../../theme";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import ApiService from "../../services/ApiService"; // API service for backend calls
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState(""); // State for user input
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    const apiService = new ApiService(
      process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
    );

    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      await apiService.post(`${apiVersion}/users/forgotPassword`, {
        email,
      });
      setSuccessMessage("A password reset link has been sent to your email.");
      toast.success("Reset link sent successfully!");

      setTimeout(() => navigate("/login"), 5000);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send reset link.");
      toast.error("Failed to send reset link. Please try again.");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="top"
      height="50%"
      px={2}
      m="20px"
    >
      <Typography variant="h4" gutterBottom color={colors.greenAccent[400]}>
        Reset Password
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <FormControl fullWidth margin="normal">
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          {successMessage && (
            <FormHelperText style={{ color: "green" }}>
              {successMessage}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            padding: "10px 20px",
            mt: "10px",
          }}
        >
          Send Reset Link
        </Button>
      </form>
    </Box>
  );
};

export default ResetPassword;
