import React, { useContext } from "react";
import {
  Box,
  useTheme,
  Tabs,
  Tab,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";

import { AuthContext } from "../../context/AuthContext";

const Settings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const { isLoggedIn, hasPermission } = useContext(AuthContext);

  const tabsConfig = [
    {
      route: "/settings/general",
      label: "General",
      icon: <AppSettingsAltOutlinedIcon />,
      permission: "settings.general",
    },
    {
      route: "/settings/access-rights",
      label: "Access Rights",
      icon: <PersonAddOutlinedIcon />,
      permission: "settings.userright",
    },
    {
      route: "/settings/user-account",
      label: "Manage User",
      icon: <ManageAccountsIcon />,
      permission: "settings.usermanagement",
    },
    {
      route: "/settings/smtp-settings",
      label: "SMTP Setup",
      icon: <EmailOutlinedIcon />,
      permission: "settings.smtp",
    },
  ];

  const accessibleTabs = isLoggedIn
    ? tabsConfig.filter((tab) => hasPermission(tab.permission))
    : [];

  const currentTab = accessibleTabs.findIndex((tab) =>
    location.pathname.startsWith(tab.route)
  );

  const handleTabChange = (event, newValue) => {
    if (newValue >= 0 && newValue < accessibleTabs.length) {
      navigate(accessibleTabs[newValue].route);
    } else {
      console.error("Invalid tab index:", newValue);
    }
  };

  return (
    <Box m="20px" height="85%">
      <Header title="Settings" />
      <Box
        display="flex"
        flexDirection={isMobileOrTablet ? "column" : "row"}
        height="100%"
        sx={{ mt: "20px" }}
      >
        <Box
          sx={{
            width: isMobileOrTablet ? "100%" : "220px",
          }}
        >
          <Tabs
            orientation={isMobileOrTablet ? "horizontal" : "vertical"}
            variant={isMobileOrTablet ? "scrollable" : "standard"}
            value={currentTab >= 0 ? currentTab : false}
            onChange={handleTabChange}
            sx={{
              borderRight: isMobileOrTablet ? "none" : 1,
              borderBottom: isMobileOrTablet ? 1 : "none",
              borderColor: "divider",
              width: isMobileOrTablet ? "100%" : "auto",
              "& .MuiTab-root": {
                textTransform: "none",
                minWidth: isMobileOrTablet ? 80 : undefined,
                fontSize: isMobileOrTablet ? "10px" : "12px",
                "&:hover": {
                  color: "#868dfb !important",
                },
              },
              "& .Mui-selected": {
                color: "#6870fa !important",
              },
              "& .MuiTabs-indicator": {
                left: isMobileOrTablet ? undefined : 0,
                bottom: isMobileOrTablet ? 0 : undefined,
              },
            }}
          >
            {accessibleTabs.map((tab) => (
              <Tab
                key={tab.route}
                icon={tab.icon}
                iconPosition={isMobileOrTablet ? "top" : "start"}
                label={tab.label}
              />
            ))}
          </Tabs>
        </Box>

        {/* Dynamic Content Area */}
        <Box
          flexGrow={1}
          p={isMobileOrTablet ? 1 : 3}
          mt={isMobileOrTablet ? 2 : 0}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
